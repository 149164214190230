<template>
  <section class="recruitment">
    <h1>Join the team</h1>
    <p>Totem Game Dev is looking for a new board to shape the future of our game development community for the academic year 2023-2024! Are you passionate about organizing events, honing your leadership skills or in other ways contributing to our community? Explore the role descriptions below and sign up if you're interested!</p>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdqjJJRXcZ8_F3uc_Sv-a3lf7hrdeYd0rAhQ7c42fQ1XKReeA/viewform?usp=sharing" target="_blank" class="button">Sign up here!</a>
    <div class="positions-grid">
      <div class="position" v-for="position in positions" :key="position.title">
        <header class="position-header">
          <i :class="['fas', 'fa-lg', position.icon]"></i>
          <h2>{{ position.title }}</h2>
        </header>
        <div class="position-details">
          <p>{{ position.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Recruitment',
  data() {
    return {
      positions: [
        {
          icon: 'fa-flag',
          title: 'Community Team Lead',
          description: `As the Community Team Lead, you will be responsible for the Totem Game Dev community! You will make sure that the team achieves its goals and you will guide the process of coming up with new goals that fit our mission. You will have an overview of each member's responsibilities and lead the team meetings. The Community Team Lead has a lot of freedom and autonomy to help the community grow in ways that are relevant to students from all over the Netherlands. It's also a great opportunity to build up your network within the Dutch games industry.`,
        },
        {
          icon: 'fa-money-bill-wave',
          title: 'Acquisition & Treasurer',
          description: `As our Acquisitions Person, you will be responsible for identifying potential game development projects and partnerships that align with our team's goals and objectives. You'll research the latest game development trends and technologies and maintain relationships with industry contacts and key stakeholders. You'll also negotiate and manage contracts, agreements, and licenses related to game development projects and collaborate with other team members to ensure that our acquisition decisions align with our project goals and priorities. As our Treasurer, you will manage the team's finances, including budgeting, accounting and financial reporting.`,
        },
        {
          icon: 'fa-users',
          title: 'Game Dev Team Manager',
          description: `As the Game Development Team manager, you will be responsible for managing small teams of student game developers to create their own games. You will oversee the development process from start to finish, helping the teams achieve their goals and deliver fun games. You will have weekly meetings with the game development teams to keep up with their progress, help if needed and keep them motivated.`,
        },
        {
          icon: 'fa-gamepad-alt',
          title: 'Game Developer',
          description: `Totem Game Dev is forming a team of experienced developers to work on a year-long project! As a member of the team, you will have the chance to put your skills to work alongside other people just as passionate as you. We plan to operate as a part-time studio, with regular meetings and deadlines. Together, we will learn about the whole process of creating and releasing a game. We are looking for members who prefer a hands-on approach and who are proactive when working with others.`
        }
      ],
    }
  },
}
</script>


<style lang="scss" scoped>
.positions-grid {
  margin-top: 2rem;
}

.recruitment {
  h1 {
    margin-bottom: 1rem;
  }

  h1 + p {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.3;
  }

  .positions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;

    .position {
      padding: 2rem 3rem;
      font-size: 16px;
      background-color: var(--color-bg-light);
      border-radius: 6px;
      border-left: 8px solid var(--color-purple-light);
      
      header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        i {
          margin-right: 20px;
        }
      }
      
      &-details {
        font-size: 16px;
        color: #8D8CA2;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .recruitment {
    .positions-grid {
      grid-template-columns: 1fr;

      .position {
        padding: 2rem;
      }
    }
  }
}
</style>